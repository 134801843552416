import {NgModule} from "@angular/core";
import {CommonModule, DatePipe} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
    NgbAccordionModule, NgbAlertModule, NgbCollapse,
    NgbDatepickerModule, NgbDropdownModule, NgbNavModule, NgbPagination, NgbTooltipModule, NgbTypeaheadModule
} from "@ng-bootstrap/ng-bootstrap";
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from "ngx-mask";
import {DelayedInputDirective} from './directives/delayed-input.directive';
import {NgSelectModule} from "@ng-select/ng-select";
import {A11yModule} from "@angular/cdk/a11y";
import {NgApexchartsModule} from 'ng-apexcharts';
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {EnumKeyPipe} from "./pipes/enum-key.pipe";

@NgModule({
    imports: [
        CommonModule,
        A11yModule,
        FormsModule,
        ReactiveFormsModule,
        NgbAccordionModule,
        NgbDropdownModule,
        NgbDatepickerModule,
        NgbNavModule,
        NgbCollapse,
        NgbTooltipModule,
        NgbPagination,
        NgbAlertModule,
        NgxMaskDirective,
        NgxMaskPipe,
        NgSelectModule,
        NgApexchartsModule,
        BsDatepickerModule,
    ],
    exports: [
        CommonModule,
        A11yModule,
        FormsModule,
        ReactiveFormsModule,
        NgbAccordionModule,
        NgbDropdownModule,
        NgbDatepickerModule,
        NgbTypeaheadModule,
        NgbNavModule,
        NgbCollapse,
        NgbTooltipModule,
        NgbPagination,
        NgSelectModule,
        NgxMaskDirective,
        NgxMaskPipe,
        DelayedInputDirective,
        EnumKeyPipe,
    ],
    providers: [
        DatePipe,
        provideNgxMask()
    ],
    declarations: [
        DelayedInputDirective,
        EnumKeyPipe,
    ]
})
export class SharedModule {
}
