export const environment = {
    production: false,
    environmentName: 'test',
    storagePrefix: 'mta_plus_test',
    title: 'MTA Plus - Test',
    apiUrl: '//test-api.mta-gte.co.uk/api/v1',
    webPath: '//test-api.mta-gte.co.uk',
    localPath: 'https://test.mta-gte.co.uk',
    sentryDSN: null,
};
