import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'enumKey',
})
export class EnumKeyPipe implements PipeTransform {
    public transform(enumObj: any, value: any): string | undefined
    {
        const key = Object.keys(enumObj).find(key => enumObj[key] === value);
        return key ? key.replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2').replace(/([a-z0-9])([A-Z])/g, '$1 $2') : undefined;
    }
}
