import {Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {debounce, fromEvent, Subject, takeUntil, timer} from "rxjs";

@Directive({
    selector: '[appDelayedInput]'
})
export class DelayedInputDirective implements OnInit, OnDestroy {
    @Input() public delay: number = 500;
    @Input() public listenType: string = 'keyup';
    @Output() public delayedInput: EventEmitter<Event> = new EventEmitter<Event>();
    private destroy: Subject<void> = new Subject<void>();

    public constructor(
        private elementRef: ElementRef<HTMLInputElement>
    ) {
    }

    public ngOnInit(): void
    {
        fromEvent(this.elementRef.nativeElement, this.listenType)
            .pipe(
                debounce(() => timer(this.delay)),
                takeUntil(this.destroy)
            )
            .subscribe((event: Event) => this.delayedInput.emit(event));
    }

    public ngOnDestroy(): void
    {
        this.destroy.next();
    }
}
